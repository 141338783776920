<template>
  <div class="send-code-container">
    <div class="send-text-wp" v-if="!waitFlag" @click="sendCodeHandle">
      <span class="send-text" :style="{color: `${fontColor}`}">{{ `Send Code` }}</span>
      <div class="loading-wp" v-show="loading">
        <Loading />
      </div>
    </div>
    <div class="send-text-wp" v-else>
      <span class="send-text forbid-click" :style="{color: `${fontColor}`}">{{ `${countNum} ${`Send Code`}` }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, ref, useDebounce, type Ref } from "#imports";

const props = defineProps({
  // ps： need promise
  sendFunction: {
    type: Function,
    default: () => {
      let noopPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        },1500)
      });
      return noopPromise;
    },
  },
  fontColor: {
    type: String,
    default: '#20d691'
  },
});

let loading: Ref<boolean> = ref(false);
let waitFlag: Ref<boolean> = ref(false);
let countNum: Ref<number> = ref(60);
let countTimer: any = ref(null);

const sendCodeHandle = useDebounce(
  () => {
    if (loading.value) {
      return;
    }
    loading.value = true;
    props
      .sendFunction()
      .then((res: any) => {
        loading.value = false;
        countDownTime(60);
      })
      .catch((err: any) => {
        console.log(err);
        loading.value = false;
      });
  },
  1000,
  {
    leading: false,
    trailing: true,
  }
);

const countDownTime = (second = 60) => {
  waitFlag.value = true;
  countNum.value = second;
  countTimer.value = setInterval(() => {
    if (countNum.value === 0) {
      clearInterval(countTimer.value!);
      countTimer.value = null;
      countNum.value = second;
      waitFlag.value = false;
      return;
    }
    countNum.value--;
  }, 1000);
};

const resetTimer = () => {
  window.clearInterval(countTimer.value!);
  countTimer.value = null;
  waitFlag.value = false;
};

onBeforeUnmount(() => {
  resetTimer();
});

defineExpose({
  resetTimer,
});
</script>
<style lang="scss" scoped>
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.send-code-container {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .send-text-wp {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      flex-shrink: 0;
      display: inline-block;
      padding: 2px 8px;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      &.forbid-click{
        cursor: not-allowed;
      }
    }
    .loading-wp {
      -webkit-animation: spin 1.5s linear 1.5s 5 alternate;
      animation: spin 1.5s linear infinite;
      height: 14px;
      width: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
